import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptorModule } from './core/interceptors/request-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from './shared/components/dialog-box/dialog.module';
import { DialogService } from './shared/components/dialog-box/dialog.service';
import { NgHttpLoaderModule } from 'ng-http-loader';

// Import the module from the SDK
import { filter } from 'rxjs/operators';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { AuthConfigModule } from './auth-config.module';
import { AppInsightsService } from './shared/service/appinsight.service';
import { ErrorHandlerService } from './shared/service/error-handler.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpRequestInterceptorModule,
    BrowserAnimationsModule,
    AuthConfigModule,
    NgHttpLoaderModule.forRoot()
    ],
  providers: [AppInsightsService, { provide: ErrorHandler, useClass: ErrorHandlerService }],
  bootstrap: [AppComponent]
})
export class AppModule {constructor(private readonly eventService: PublicEventsService) {
  this.eventService
    .registerForEvents()
    .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
    .subscribe((config) => {
      console.log('ConfigLoaded', config);
    });
} }
