export const environment = {
  production: false,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  auth0Domain:'https://nhsla-dev1.eu.auth0.com',
  auth0ClientId: 'OYb2DG3YbBf9iQND3w0UdMV4RMjrebyM',
  //auth0Domain:'https://vinish-george-dev.us.auth0.com',
  //auth0ClientId: '4jHTXBp3ubaOYAgZh2RcI6V9GIqCk0Nr'  
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b'
  }
};
