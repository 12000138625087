import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly router: Router,
    public oidcSecurityService: OidcSecurityService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (localStorage.getItem('sessionToken')) {
      return true;
    } else {

    return   this.oidcSecurityService.isAuthenticated$.pipe(
        map(({ isAuthenticated }) => {
          // allow navigation if authenticated
          if (isAuthenticated && !localStorage.getItem('sessionToken')) {
            this.router.navigate(['/sso-sign-in']);
            return false;
          }else{
            // redirect if not authenticated
            this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
            return false;
          }

        }));
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.canActivate(next, state);

  }

}
