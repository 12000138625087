import { HttpClient } from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import { Url } from '../constants/url-constants';

@Injectable({providedIn:'root'})
export class SharedService {
  constructor(
    private readonly http: HttpClient
  ) {}
  
  closeDialogOnSessionTimeOut = new EventEmitter();

  broadcast(body) {
    const href = `${Url.USER}/call/broadcast`;
    //return this.http.post(href, body);
  }

  sendNotification(body) {
    const href = `${Url.USER}/call/notification`;
    //return this.http.post(href, body);
  }

  register(id, event) {
    const href = `${Url.USER}/call/register/${id}/${event}`;
    //return this.http.get(href);
  }
  unsubscribe(id, event) {
    const href = `${Url.USER}/call/unsubscribe/${id}/${event}`;
    //return this.http.get(href);
  }  
}