import { ErrorHandler, Injectable } from "@angular/core";
import { AppInsightsService } from "./appinsight.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private appInsightsService: AppInsightsService) {
        super();
    }

    handleError(error: Error) {
        this.appInsightsService.logException(error); // Manually log exception
    }
}